body {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://www.teahub.io/photos/full/17-179560_comic-book-wallpapers-100-quality-hd-comic-book.jpg");
}

.container-fluid {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
}

.container-mobile {
  width: 100%;
  padding-right: 0px;
  padding-left: 0px;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.hero-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("https://www.teahub.io/photos/full/17-179560_comic-book-wallpapers-100-quality-hd-comic-book.jpg");
  height: 300px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}

.topContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchContainer {
  min-height: 100vh;
}

.resultContainer {
  background-color: white;
  padding: 25px;
  min-height: 1vh;
  width: 100%;
}

.favContainer {
  background-color: white;
  min-height: 100vh;
}

.loaderContainer {
  width: 100%;
  height: 50px;
  text-align: center;
}

.Accordion-button {
  margin-left: auto;
  margin-right: auto;
}

.marvel-logo {
  text-decoration: none;
  font-weight: bolder;
  font-size: 1.5rem;
}

.show {
  width: auto;
}
